import {z} from "zod";
import {Util} from "./Util";

const ALL_STATE_KEY = "saved-state";

const GAME_LIST_SCHEMA = z.array(z.string())

const PUZZLE_STATE_SCHEMA = z.object({
  value: z.string(),
  solved: z.boolean(),
})

const GAME_STATE_SCHEMA = z.object({
  numWrongGuesses: z.object({
    left: z.number(),
    right: z.number()
  }),
  topLeft: PUZZLE_STATE_SCHEMA,
  bottomLeft: PUZZLE_STATE_SCHEMA,
  left: PUZZLE_STATE_SCHEMA,
  right: PUZZLE_STATE_SCHEMA,
  topRight: PUZZLE_STATE_SCHEMA,
  bottomRight: PUZZLE_STATE_SCHEMA,
})

const ALL_GAMES_STATE_SCHEMA = z.record(GAME_STATE_SCHEMA)

const THEME_SCHEMA = z.union([z.literal("dark"), z.literal("light")])

const STATE_SCHEMA = z.object({
  gameList: GAME_LIST_SCHEMA,
  gameState: ALL_GAMES_STATE_SCHEMA,
  theme: THEME_SCHEMA.optional(),
})

export namespace UserStorage {
  const loadState = (): z.infer<typeof STATE_SCHEMA> => {
    document.onvisibilitychange = () => {
      if (document.visibilityState === "hidden") saveState()
    };
    try {
      const storedString = localStorage.getItem(ALL_STATE_KEY) ?? "";
      const storedObject = JSON.parse(storedString)
      return STATE_SCHEMA.parse(storedObject);
    } catch {
      return {
        gameList: [],
        gameState: {},
      }
    }
  }

  const saveState = () => {
    localStorage.setItem(ALL_STATE_KEY, JSON.stringify(state))
  }

  const state = loadState();

  export const saveSolvedGame = (name: string) => {
    delete state.gameState[name]
    if (state.gameList.includes(name)) return;
    state.gameList.push(name)
  }

  export const deleteSolvedGame = (name: string) => {
    delete state.gameState[name]
    if (!state.gameList.includes(name)) return;
    state.gameList = state.gameList.filter((other) => other !== name);
  }

  export const isGameSolved = (name: string): boolean => {
    return state.gameList.includes(name)
  }

  export const getTheme = (): "dark" | "light" => {
    return state.theme ?? Util.systemThemePreference();
  }

  export const setTheme = (theme: "dark" | "light") => {
    state.theme = theme;
  }

  export const getGameState = (name: string): z.infer<typeof GAME_STATE_SCHEMA> | undefined => {
    return state.gameState[name]
  }

  export const setGameState = (name: string, newState: z.infer<typeof GAME_STATE_SCHEMA>) => {
    state.gameState[name] = newState;
  }
}
