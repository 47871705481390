import {Util} from "./Util";
import raw_dictionary from "./words_alpha.txt"

const cacheDictionary = async (retriesLeft: number = 5): Promise<Set<string> | Error> => {
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), 10000)
  try {
    const response = await fetch(raw_dictionary, {signal: controller.signal})
    clearTimeout(timeoutId)
    if (!response.ok) {
      if (retriesLeft === 0) return new Error("Failed to get dictionary. Try refreshing the page.")
      await Util.sleep(1000)
      return cacheDictionary(retriesLeft - 1)
    }
    const text = await response.text();
    const set = new Set(text.split("\n").map((word) => word.trim()))
    if (set.size > 10000) return set;
    if (retriesLeft === 0) return new Error("Failed to get dictionary. Try refreshing the page.")
    await Util.sleep(1000)
    return cacheDictionary(retriesLeft - 1)
  } catch {
    if (retriesLeft === 0) return new Error("Failed to get dictionary. Try refreshing the page.")
    await Util.sleep(1000)
    return cacheDictionary(retriesLeft - 1)
  }
}

const cachedDictionary: Promise<Set<string> | Error> = cacheDictionary();

export namespace EnglishDictionary {

  const getDictionary = async (): Promise<Set<string> | Error> => {
    return cachedDictionary
  }

  export const isWord = async (word: string): Promise<boolean | Error> => {
    if (word.length === 0) return false
    const dictionary = await getDictionary();
    if (!(dictionary instanceof Set)) return dictionary;
    return dictionary.has(word.toLowerCase())
  }
}
