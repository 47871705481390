import React from "react";
import "./PuzzleKeyboard.css";
import {Map} from "immutable";
import { Util } from "../util/Util";

interface PuzzleKeyboardProps {
  onClick: (letter: LetterType) => void
  onDelete: () => void,
  onGuess: () => void,
  lettersLeft: Map<LetterType, number>
}

export const PuzzleKeyboard = (props: PuzzleKeyboardProps): React.ReactElement => {
    return <div className="puzzle-keyboard">
        <div className={"keyboard-row"} style={{justifyContent: "space-between", width: "100%"}}>
            <button className={"keyboard-key wj-button"} onClick={props.onGuess} style={{width: "unset"}}>GUESS</button>
            <button className={"keyboard-key wj-button"} onClick={props.onDelete} style={{width: "unset"}}>DELETE</button>
        </div>
        <KeyboardRow letters={TOP_ROW} onClick={props.onClick} lettersLeft={props.lettersLeft} key="top"/>
        <KeyboardRow letters={MIDDLE_ROW} onClick={props.onClick} lettersLeft={props.lettersLeft} key="middle"/>
        <KeyboardRow letters={BOTTOM_ROW} onClick={props.onClick} lettersLeft={props.lettersLeft} key="bottom"/>
    </div>
}

export const ALL_LETTERS = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"] as const
const TOP_ROW = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"] as const
const MIDDLE_ROW = ["A", "S", "D", "F", "G", "H", "J", "K", "L"] as const
const BOTTOM_ROW = ["Z", "X", "C", "V", "B", "N", "M"] as const

export type LetterType = typeof ALL_LETTERS[number]
export const isLetterType = (letter: string): letter is LetterType => {
    return (TOP_ROW as readonly string[]).includes(letter) 
        || (MIDDLE_ROW as readonly string[]).includes(letter) 
        || (BOTTOM_ROW as readonly string[]).includes(letter)
}

interface KeyboardRowProps {
    letters: readonly LetterType[];
    onClick: (letter: LetterType) => void;
    lettersLeft: Map<LetterType, number>
}

const KeyboardRow = (props: KeyboardRowProps): React.ReactElement => {
    return <div className="keyboard-row">
         {props.letters.map((letter) => 
            <KeyboardKey key={letter} 
                         number={props.lettersLeft.get(letter) ?? 0} 
                         letter={letter} 
                         onClick={props.onClick}/>)}
    </div>
}

interface KeyboardKeyProps {
    number: number;
    letter: LetterType;
    onClick: (letter: LetterType) => void
}

const KeyboardKey = (props: KeyboardKeyProps): React.ReactElement => {
    const hasNumber = props.number > 0 && Number.isInteger(props.number) 
    const isInvalid = props.number < 0 || !Number.isInteger(props.number);
    return <button className={Util.classname("keyboard-key", "wj-button", isInvalid && "keyboard-key-invalid")} disabled={!hasNumber || isInvalid} onClick={() => props.onClick(props.letter)}>
      <span className="key-letter">{props.letter}</span>
      <span className="key-number">{props.number}</span>
    </button>
}

