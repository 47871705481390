import React from "react";
import shareicon from "../icon/share.svg";
import "./ShareButton.css";
import copy from "copy-to-clipboard";


export const ShareButton = (props: {textToCopy: string}): React.ReactElement => {
  const [copyTimeout, setCopyTimeout] = React.useState<undefined | NodeJS.Timeout>(undefined)
  const text = copyTimeout === undefined ? "Share your win" : "Copied to clipboard"
  React.useEffect(() => {
    return () => {
      clearTimeout(copyTimeout)
    }
  }, [])

  return <button className={"share-button wj-button"} onClick={() => {
    clearTimeout(copyTimeout);
    copy(props.textToCopy)
    const timeout = setTimeout(() => {
      setCopyTimeout(undefined)
    }, 2000)
    setCopyTimeout(timeout)
  }}>
    {text}
    {copyTimeout === undefined && <img src={shareicon} className="share-icon wj-icon" alt="share icon"/>}
  </button>
}
