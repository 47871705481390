import React from "react";
import {Icon} from "../icon/Icon";
import xicon from "../icon/x.svg";

interface HelpPopupProps {
  hidePopup: () => void;
}

export const HelpPopup = (props: HelpPopupProps): React.ReactElement => {
  return <div className={"popup-overlay help-overlay"}
              onClick={(e) => e.target === e.currentTarget && props.hidePopup()}>
    <div className={"help-popup"}>
      <div className={"help-top-row"}>
        <h2>Instructions</h2>
        <button className={"icon-button"} onClick={props.hidePopup}>
          <Icon src={xicon} alt={"Close icon"}/>
        </button>
      </div>
      <p>
        The goal of the game is to find the missing words in the puzzle. There are two sources of
        information:
      </p>
      <ul>
        <li>
          The words in the puzzle
        </li>
        <li>
          The letter bank
        </li>
      </ul>
      <p>
        The words in the puzzle are all related by definition. A double line connecting two words
        means that those words are synonyms. In this puzzle, two words are synonyms if they can
        be easily used in a sentence the same way using at least one of their definitions.
        For example, BLUE and SAD are synonyms.
      </p>
      <p>
        A double line with two slashes through it means that those words are antonyms. In this puzzle,
        two words are antonyms if they mean opposite things in some context. This is less clear of a
        definition, and is where you have to trust the puzzle creator. For example, SELL and BUY are
        antonyms, because they mean opposite sides of a financial transaction.
      </p>
      <p>
        Words have lots of meanings! If you're stuck, try thinking of different ways a word may be used.
        For example, MINE could be "to extract ore from the ground" or "a place to extract ore from the
        ground" or "an explosive devices that goes off when touched" or simply "possessed by me." A word
        may be interpreted very differently (and even be a different part of speech) from one connection
        to the next.
      </p>
      <p>
        The letter bank is used to check your work as you're going and perhaps a source of inspiration
        for finding more word ideas. The green keys tell you how many of each letter you have left.
        If a key turns red, you're using too many of that letter.
      </p>
    </div>
  </div>
}
