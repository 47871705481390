import React from 'react';
import { WordJoinGameProps } from './game/WordJoinGame';
import {Util} from "./util/Util";
import classname = Util.classname;
import './GamePicker.css';
import {UserStorage} from "./util/UserStorage";

interface GamePickerProps {
  games: WordJoinGameProps[]
  currentGame: WordJoinGameProps["name"] | undefined
  onGameChange: (game: WordJoinGameProps) => void
}

export const GamePicker = (props: GamePickerProps): React.ReactElement => {
  return <div className={"game-picker"}>
    {props.games.map((game) => {
      return <button className={classname("game-picker-game", "wj-button", props.currentGame === game.name && "game-picker-current", UserStorage.isGameSolved(game.name) && "game-picker-solved")}
                     key={`game-${game.name}`}
                     onClick={() => {
        if (props.currentGame === game.name) return
        props.onGameChange(game)
      }}>
        {game.name}
      </button>
    })}
  </div>
}
