import useResizeObserver from "@react-hook/resize-observer";
import React from "react";



export namespace Util {
    export const classname = (...classnames: (string | unknown)[]): string => {
        const filtered: string[] = classnames.filter((item): item is string => typeof item === "string" && item.trim().length > 0);
        return filtered.map((item) => item.trim()).join(" ")
    }

    export const isIterable = (obj: unknown): boolean => {
        if (typeof obj !== "object") return false
        if (!obj) return false
        if (Symbol.iterator in obj) {
            return typeof obj[Symbol.iterator] === 'function';
        }
        return false
    }

    export const useSize = (target: React.RefObject<HTMLElement>): {width: number, height: number} | undefined => {
        const [size, setSize] = React.useState<{width: number, height: number} | undefined>(undefined)

        React.useLayoutEffect(() => {
            setSize(target.current?.getBoundingClientRect())
        }, [target])

        // Where the magic happens
        useResizeObserver(target, (entry) => setSize(entry.contentRect))
        return size
    }

    export const systemThemePreference = (): "dark" | "light" => {
        const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
        return prefersDarkScheme ? "dark" : "light";
    }

    export const sleep = (ms: number): Promise<void> => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    export const useForceUpdate = (): (() => void) => {
        const [, setV] = React.useState(0);
        return () => {
            setV((prev) => prev + 1)
        }
    }
}
