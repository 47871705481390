import React from 'react';
import './App.css';
import './button/IconButton.css'
import {Router} from "./Router";
const App = (): React.ReactElement => {
  return (
    <div className="app">
      <Router/>
    </div>
  );
}

export default App;
