import React from 'react';
import {ThemeContext} from "../util/ThemeContext";

export interface Point {
  x: number,
  y: number,
}
interface ArrowCanvasProps {
  samePairs: [Point, Point][]
  differentPairs: [Point, Point][]
}


const BORDER_SIZE = 2;
const LINE_SIZE = 9;
const CROSS_SIZE = 15;

const drawLine = (ctx: CanvasRenderingContext2D, from: Point, to: Point, color?: "white" | "black") => {
  const computedStyle = getComputedStyle(ctx.canvas);
  const bgColor = computedStyle.getPropertyValue("--background-color")
  const textColor = computedStyle.getPropertyValue("--text-color-1")

  ctx.beginPath(); // Start a new path
  if (!color || color === "black") {
    ctx.lineWidth = LINE_SIZE;
    ctx.strokeStyle = textColor;
    ctx.moveTo(from.x, from.y);
    ctx.lineTo(to.x, to.y);
    ctx.stroke();
  }
  if (!color || color === "white") {
    ctx.lineWidth = LINE_SIZE - 2 * BORDER_SIZE;
    ctx.strokeStyle = bgColor;
    ctx.moveTo(from.x, from.y);
    ctx.lineTo(to.x, to.y);
    ctx.stroke();
  }
  ctx.closePath()
}

const getCenter = (from: Point, to: Point): Point => {
  return {
    x: (from.x + to.x) / 2,
    y: (from.y + to.y) / 2,
  }
}

/**
 * Rotates to around from by angleRadians radians (clockwise)
 */
const rotate = (from: Point, to: Point, angleRadians: number): Point => {
  const dx = to.x - from.x;
  const dy = to.y - from.y;
  return {
    x: dx * Math.cos(angleRadians) - dy * Math.sin(angleRadians) + from.x,
    y: dx * Math.sin(angleRadians) + dy * Math.cos(angleRadians) + from.y
  }
}

const setLength = (from: Point, to: Point, length: number): Point => {
  const dx = to.x - from.x;
  const dy = to.y - from.y;
  const factor = length / Math.sqrt(dx ** 2 + dy ** 2)
  return {
    x: from.x + factor * dx,
    y: from.y + factor * dy,
  }
}

const add = (point: Point, delta: Point) => {
  return {
    x: point.x + delta.x,
    y: point.y + delta.y,
  }
}

const negate = (point: Point): Point => {
  return {
    x: -point.x,
    y: -point.y,
  }
}


export const ArrowCanvas = (props: ArrowCanvasProps): React.ReactElement => {
  const canvasContext = React.useRef<CanvasRenderingContext2D | undefined>(undefined);
  React.useContext(ThemeContext) // This triggers rerender on theme change
  React.useEffect(() => {
    const ctx = canvasContext.current
    if (!ctx) return
    const canvas = ctx.canvas;
    canvas.width = ctx.canvas.clientWidth
    canvas.height = ctx.canvas.clientHeight
    props.samePairs.forEach(([from, to]) => {
      drawLine(ctx, from, to)
    })

    props.differentPairs.forEach(([from, to]) => {
      const midPoint = getCenter(from, to)
      const rotatedEnd = rotate(midPoint, to, Math.PI / 4)
      const scaledEnd = setLength(midPoint, rotatedEnd, CROSS_SIZE)
      const scaledEndShorter = setLength(midPoint, scaledEnd, CROSS_SIZE - BORDER_SIZE)
      const scaledStart = rotate(midPoint, scaledEnd, Math.PI)
      const scaledStartShorter = setLength(midPoint, scaledStart, CROSS_SIZE - BORDER_SIZE)
      const deltaEnd = setLength(midPoint, to, 10)
      const absDelta = add(deltaEnd, negate(midPoint))

      drawLine(ctx, from, to, "black")
      drawLine(ctx, add(scaledStart, absDelta), add(scaledEnd, absDelta), "black")
      drawLine(ctx, add(scaledStart, negate(absDelta)), add(scaledEnd, negate(absDelta)), "black")

      drawLine(ctx, from, to, "white")
      drawLine(ctx, add(scaledStartShorter, absDelta), add(scaledEndShorter, absDelta), "white")
      drawLine(ctx, add(scaledStartShorter, negate(absDelta)), add(scaledEndShorter, negate(absDelta)), "white")
    })
    return () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
    }
  })
  return <canvas ref={(element) => canvasContext.current = element?.getContext("2d") ?? undefined}
                 style={{width: "100%", height: "100%"}}/>
}
