import React from 'react';
import { WordJoinPuzzle } from './WordJoinPuzzle';
import {ALL_LETTERS, isLetterType, LetterType, PuzzleKeyboard} from "./PuzzleKeyboard";
import {Map} from "immutable";
import "./WordJoinGame.css"
import {ArrowCanvas, Point} from "./ArrowCanvas";
import {Util} from "../util/Util";
import {UserStorage} from "../util/UserStorage";
import isGameSolved = UserStorage.isGameSolved;
import saveSolvedGame = UserStorage.saveSolvedGame;
import getGameSate = UserStorage.getGameState;
import {EnglishDictionary} from "../util/EnglishDictionary";
import {ShareButton} from "../button/ShareButton";

export interface WordJoinGameProps {
  gameData: WordJoinGameData
  name: string
}

export const WordJoinGame = (props: WordJoinGameProps): React.ReactElement => {
  const userStorageSolved = isGameSolved(props.name)
  const userStorageState = getGameSate(props.name)
  const [currentWordState, setCurrentWordState] = React.useState<Map<keyof WordJoinGameData, {value: string, solved: boolean}>>(calcuateInitialWordState(props.gameData, userStorageSolved, userStorageState))
  const [lastFocusedInput, setLastFocusedInput] = React.useState<{element: HTMLInputElement, position: keyof WordJoinGameData} | undefined>()
  const [numWrongGuesses, setNumWrongGuesses] = React.useState(userStorageState?.numWrongGuesses ?? {left: 0, right: 0});
  const puzzleDivRef = React.useRef<HTMLDivElement>(null);
  const size = Util.useSize(puzzleDivRef);
  const isSolved = [...currentWordState.values()].every((obj) => obj.solved)
  const narrowMode = size && size.width < WIDTH_CUTOFF;
  // const narrowMode = true;
  const [currentError, setCurrentError] = React.useState<Error | undefined>(undefined);

  if (isSolved && !userStorageSolved) {
    saveSolvedGame(props.name)
  }

  saveCurrentState(props.name, currentWordState, numWrongGuesses)

  const lettersLeft = subtractLetterMap(
    wordsToLetterMap(Object.values(props.gameData).map((value) => value.word)),
    wordsToLetterMap([...currentWordState.values()].map((value) => value.value))
  )

  const doGuess = async (word: string, position: keyof WordJoinGameData): Promise<void> => {
    const isWord = await EnglishDictionary.isWord(word);
    if (typeof isWord !== "boolean") {
      setCurrentError(isWord)
      return;
    }
    if (!isWord) {
      setCurrentError(new Error(`"${word.toUpperCase()}" is not a word.`))
      return;
    }
    setCurrentError(undefined)
    if (word.toUpperCase() === props.gameData[position].word.toUpperCase()) {
      const newState = currentWordState.set(position, {value: word, solved: true})
      setCurrentWordState(newState)
      setLastFocusedInput(undefined)
    } else {
      setNumWrongGuesses((prev) => ({
        left: prev.left + (position === "left" ? 1 : 0),
        right: prev.right + (position === "right" ? 1 : 0)}))
    }
  }

  const positionPoints = React.useMemo<WordPoints | undefined>(() => {
    if (!size) return undefined;
    return {
      topLeft: positionToXY("topLeft", size.width),
      bottomLeft: positionToXY("bottomLeft", size.width),
      left: positionToXY("left", size.width),
      topRight: positionToXY("topRight", size.width),
      bottomRight: positionToXY("bottomRight", size.width),
      right: positionToXY("right", size.width),
    }
  }, [size])

  React.useLayoutEffect(() => {
    if (lastFocusedInput === undefined && puzzleDivRef.current) {
      puzzleDivRef.current.querySelector<HTMLInputElement>(".word-join-puzzle input")?.focus()
    }
  }, [lastFocusedInput, positionPoints])

  const resultString = getResultString(
    {numWrongGuesses: numWrongGuesses.left, solved: currentWordState.get("left")?.solved ?? false},
    {numWrongGuesses: numWrongGuesses.right, solved: currentWordState.get("right")?.solved ?? false}
  )

  return <div className="word-join-game">
    <div className="word-join-game-name">{props.name}</div>
    <div className="word-join-game-puzzles"
         ref={puzzleDivRef}
         style={narrowMode ? {height: INPUT_HEIGHT * 6 + INPUT_ROW_SPACING * 5} : {height: INPUT_HEIGHT * 3 + INPUT_ROW_SPACING * 2}}>
      {positionPoints && <ArrowCanvas samePairs={[
        [positionPoints.topLeft, positionPoints.left],
        [positionPoints.bottomLeft, positionPoints.left],
        [positionPoints.topRight, positionPoints.right],
        [positionPoints.bottomRight, positionPoints.right],
      ]} differentPairs={[
        [positionPoints.left, positionPoints.right]
      ]}/>}
      {positionPoints && Object.entries(props.gameData).map((entry) => {
        const position = entry[0] as keyof WordJoinGameData
        const wordData = entry[1] as WordData
        const {x, y} = positionPoints[position]
        return <WordJoinPuzzle key={position}
                               style={{
                                 left: x,
                                 top: y,
                                 transform: "translateX(-50%) translateY(-50%)",
                                 background: position === lastFocusedInput?.position ? "var(--selected-color)" : "var(--background-color)"
                               }}
                               solved={currentWordState.get(position)?.solved ?? false}
                               value={currentWordState.get(position)?.value ?? ""}
                               answer={wordData.word}
                               onFocus={(e) => {
                                 setLastFocusedInput({
                                   element: e.target,
                                   position: position,
                                 })
                               }}
                               onChange={(value) => {
                                 setCurrentWordState(currentWordState.set(position, {value: value, solved: false}))
                               }}
                               onGuess={(word) => {
                                 doGuess(word, position)
                               }}/>
      })}
    </div>
    {<span className={"result-string"}>{resultString}</span>}
    <div className="error-div">{currentError?.message ?? <>&nbsp;</>}</div>
    {isSolved && <div className="success-div">
        <span>Yay! You did it!</span>
        <ShareButton textToCopy={
          `${resultString}\n\nhttps://wordonyms.com`
        }/>
        <button className={"wj-button"}
            onClick={() => {
          UserStorage.deleteSolvedGame(props.name)
          setCurrentWordState(calcuateInitialWordState(props.gameData, isGameSolved(props.name), undefined))
          setNumWrongGuesses({left: 0, right: 0})
          setLastFocusedInput(undefined)
        }}>Reset puzzle
        </button>
    </div>}
    {!isSolved && <PuzzleKeyboard onClick={(letter) => {
      if (!lastFocusedInput) return
      const prevState = currentWordState.get(lastFocusedInput.position)
      if (prevState?.solved) return;
      setCurrentWordState(currentWordState.set(lastFocusedInput.position, {
        value: (prevState?.value ?? "") + letter,
        solved: false,
      }))
    }}
                                  onDelete={() => {
                                    if (!lastFocusedInput) return
                                    const prevState = currentWordState.get(lastFocusedInput.position)
                                    if (!prevState || prevState.solved) return;
                                    setCurrentWordState(currentWordState.set(lastFocusedInput.position, {
                                      value: prevState.value.slice(0, -1),
                                      solved: false,
                                    }))
                                  }}
                                  onGuess={() => {
                                    if (!lastFocusedInput) return
                                    const prevState = currentWordState.get(lastFocusedInput.position)
                                    if (!prevState || prevState.solved) return;
                                    doGuess(prevState.value, lastFocusedInput.position)
                                  }}
                                  lettersLeft={lettersLeft}/>
    }
  </div>
}

const positionToXY = (key: keyof WordJoinGameData, width: number): Point => {
  const isNarrow = width < WIDTH_CUTOFF
  const paddingSize = isNarrow ? (width - INPUT_MAX_WIDTH) / 3 : (width - 2 * INPUT_MAX_WIDTH) / 3
  const getY = (row: number) => {
    return row * INPUT_ROW_SPACING + (row + 0.5) * INPUT_HEIGHT
  }
  const smallLeft = width / 3
  const bigLeft = width - smallLeft
  switch (key) {
    case "topLeft":
      if (isNarrow) return {x: smallLeft, y: getY(0)}
      return {x: INPUT_MAX_WIDTH / 2, y: getY(0)}
    case "bottomLeft":
      if (isNarrow) return {x: smallLeft, y: getY(2)}
      return {x: INPUT_MAX_WIDTH / 2, y: getY(2)}
    case "left":
      if (width < WIDTH_CUTOFF) {
        return {x: bigLeft, y: getY(1)}
      }
      return {x: INPUT_MAX_WIDTH / 2 + paddingSize, y: getY(1)}
    case "topRight":
      if (width < WIDTH_CUTOFF) {
        return {x: smallLeft, y: getY(3)}
      }
      return {x: width - INPUT_MAX_WIDTH / 2, y: getY(0)}
    case "bottomRight":
      if (width < WIDTH_CUTOFF) {
        return {x: smallLeft, y: getY(5)}
      }
      return {x: width - INPUT_MAX_WIDTH / 2, y: getY(2)}
    case "right":
      if (width < WIDTH_CUTOFF) {
        return {x: bigLeft, y: getY(4)}
      }
      return {x: (width - INPUT_MAX_WIDTH / 2 - paddingSize), y: getY(1)}
  }
}

const INPUT_HEIGHT = 30;
const INPUT_MAX_WIDTH = 200;
const INPUT_ROW_SPACING = 30;
const WIDTH_CUTOFF = INPUT_MAX_WIDTH * 7 / 2;

export interface WordJoinGameData {
  topLeft: WordData,
  bottomLeft: WordData,
  left: WordData,
  right: WordData,
  topRight: WordData,
  bottomRight: WordData,
}

type WordPoints = {
  [Key in keyof WordJoinGameData]: Point
}

export interface WordData {
  word: string,
  startSolved: boolean,
}

export interface WordJoinPuzzleData {
  word: string

}

const wordsToLetterMap = (words: string[]): Map<LetterType, number> => {
  let map = Map<LetterType, number>();
  words.forEach((word) => {
    for (let i = 0; i < word.length; i++) {
      const char = word.charAt(i).toUpperCase();
      if (isLetterType(char)) {
        const prevCount = map.get(char, 0)
        map = map.set(char, prevCount + 1)
      }
    }
  })
  return map
}

const subtractLetterMap = (bigMap: Map<LetterType, number>, smallMap: Map<LetterType, number>): Map<LetterType, number> => {
  return Map(ALL_LETTERS.map((key) => {
    const value = bigMap.get(key, 0)
    const otherValue = smallMap.get(key, 0)
    const newValue = value - otherValue;
    //if (newValue < 0) throw new Error("Tried to subtract maps that can't be subtracted")
    return [key, newValue]
  }))
}

const calcuateInitialWordState = (gameData: WordJoinGameData, userStorageSolved: boolean, userStorageState: ReturnType<typeof UserStorage.getGameState> | undefined): Map<keyof WordJoinGameData, {value: string, solved: boolean}> => {
  return Map((Object.keys(gameData) as (keyof WordJoinGameData)[]).map((key: keyof WordJoinGameData) => {
    const wordData: WordData = gameData[key];
    const userStoragePuzzleData = userStorageState?.[key];
    const startSolved = wordData.startSolved || userStorageSolved || !!userStoragePuzzleData?.solved;
    const value = userStoragePuzzleData?.value ?? ""
    return [key, {value: startSolved ? wordData.word : value, solved: startSolved}]
  }))
}

const saveCurrentState = (name: string, state: Map<keyof WordJoinGameData, {value: string, solved: boolean}>, numWrongGuesses: {left: number, right: number}) => {
  UserStorage.setGameState(name, {
    numWrongGuesses: numWrongGuesses,
    topLeft: state.get("topLeft", {value: "", solved: false}),
    bottomLeft: state.get("bottomLeft", {value: "", solved: false}),
    left: state.get("left", {value: "", solved: false}),
    topRight: state.get("topRight", {value: "", solved: false}),
    bottomRight: state.get("bottomRight", {value: "", solved: false}),
    right: state.get("right", {value: "", solved: false}),
  })
}

const getResultString = (left: {numWrongGuesses: number, solved: boolean}, right: {numWrongGuesses: number, solved: boolean}): string => {
  //x "\u274C"
  //check "\u2705"
  //not equal "\u2260"
  const solveSide = (side: {numWrongGuesses: number, solved: boolean}): string => {
    return "\u274C".repeat(side.numWrongGuesses) + (side.solved ? "\u2705" : "")
  }
  return "\u21A3" + solveSide(left) + "\u2260" + solveSide(right).split("").reverse().join("") + "\u21A2"

}
