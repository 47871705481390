import React from "react";
import {Util} from "../util/Util";
import "./Icon.css";
import classname = Util.classname;

interface IconProps extends React.HTMLAttributes<HTMLImageElement> {
  alt: string,
  src: string,
}

export const Icon = (props: IconProps): React.ReactElement => {
  return <img {...props}
              alt={props.alt}
              className={classname(props.className, "wj-icon")}/>
}
