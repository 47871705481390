import React from 'react';
import './WordJoinPuzzle.css';

interface WordJoinPuzzleProps {
  value: string
  solved: boolean
  answer: string 
  onGuess: (word: string) => void
  onChange: (value: string) => void
  onFocus?: React.FocusEventHandler<HTMLInputElement>,
  style?: React.CSSProperties
}

export const WordJoinPuzzle = (props: WordJoinPuzzleProps): React.ReactElement => {
  const attempt = () => {
    if (props.solved) return
    props.onGuess(props.value)
  }

  const content = props.solved ? props.answer : <>
    <form onSubmit={(e) => {
                 attempt()
                 e.preventDefault()
                 e.stopPropagation()
        }}>
    <input type="text"
           inputMode={"none"}
           onFocus={props.onFocus}
           value={props.value} 
           onChange={(e) => {
            if (props.solved) return
            props.onChange(e.target.value)
        }}/>
      </form>
    </>
    
  return <div className="word-join-puzzle" style={props.style}>
    {content}
  </div>
}

