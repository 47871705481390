import {WordJoinGameData, WordJoinGameProps} from "../game/WordJoinGame";


type SimpleGameDataWord = string | [string, boolean]
interface SimpleGameData {
  inLeft: [SimpleGameDataWord, SimpleGameDataWord]
  outLeft: string,
  inRight: [SimpleGameDataWord, SimpleGameDataWord]
  outRight: string,
}

const getWord = (word: SimpleGameDataWord): string => {
  if (typeof word === "string") return word.toUpperCase();
  return word[0].toUpperCase()
}

const getShown = (word: SimpleGameDataWord): boolean | undefined => {
  if (typeof word === "string") return undefined;
  return word[1]
}

const simpleGameDataToGameData = (data: SimpleGameData): WordJoinGameData => {
  return {
    topLeft: {word: getWord(data.inLeft[0]), startSolved: getShown(data.inLeft[0]) ?? true},
    bottomLeft: {word: getWord(data.inLeft[1]), startSolved: getShown(data.inLeft[1]) ?? true},
    left: {word: getWord(data.outLeft), startSolved: false},
    topRight: {word: getWord(data.inRight[0]), startSolved: getShown(data.inRight[0]) ?? true},
    bottomRight: {word: getWord(data.inRight[1]), startSolved: getShown(data.inRight[1]) ?? true},
    right: {word: getWord(data.outRight), startSolved: false},
  }
}

const REAL_GAME_DATA: WordJoinGameProps[] = [
  {
    gameData: {
      topLeft: {word: "FALLEN", startSolved: true},
      bottomLeft: {word: "LIFELESS", startSolved: true},
      left: {word: "DEAD", startSolved: false},
      topRight: {word: "BUSTLING", startSolved: true},
      bottomRight: {word: "FUNCTIONAL", startSolved: true},
      right: {word: "ALIVE", startSolved: false},
    },
    name: "Introduction"
  },
  {
    gameData: simpleGameDataToGameData({
      inLeft: ["remaining", "entrusted"],
      outLeft: "left",
      inRight: ["correct", "just"],
      outRight: "right",
    }),
    name: "Which hand?"
  },
  {
    gameData: simpleGameDataToGameData({
      inLeft: ["demand", "strict"],
      outLeft: "exact",
      inRight: ["universal", "unspecialized"],
      outRight: "general",
    }),
    name: "Unclear"
  },
  {
    gameData: {
      topLeft: {word: "INCREASE", startSolved: true},
      bottomLeft: {word: "NURTURE", startSolved: true},
      left: {word: "RAISE", startSolved: false},
      topRight: {word: "DROP", startSolved: true},
      bottomRight: {word: "DECREASE", startSolved: true},
      right: {word: "LOWER", startSolved: false},
    },
    name: "Elevation"
  },
]

const TEST_GAME_DATA: WordJoinGameProps[] = [
  {
    gameData: {
      topLeft: {word: "AAAAAAAAAAA1", startSolved: true},
      bottomLeft: {word: "AAAAAAAAAAA3", startSolved: true},
      left: {word: "AAAAAAAAAAA2", startSolved: true},
      topRight: {word: "AAAAAAAAAAA4", startSolved: true},
      bottomRight: {word: "AAAAAAAAAAA6", startSolved: true},
      right: {word: "AAAAAAAAAAA5", startSolved: true},
    },
    name: "Test LONG"
  },
  {
    gameData: {
      topLeft: {word: "idk", startSolved: false},
      bottomLeft: {word: "idk", startSolved: false},
      left: {word: "idk", startSolved: false},
      topRight: {word: "idk", startSolved: false},
      bottomRight: {word: "idk", startSolved: false},
      right: {word: "idk", startSolved: false},
    },
    name: "Test EMPTY"
  },
]

export const ALL_GAME_DATA = [
  ...REAL_GAME_DATA,
  ...(process.env.NODE_ENV === 'production' ? [] : TEST_GAME_DATA)
]
