


import React, {Suspense} from 'react'
import {
  RouterProvider,
  createRouter,
  createRoute,
  createRootRoute,
} from '@tanstack/react-router'
import {GameView} from "./GameView";
import {WordJoinGame} from "./game/WordJoinGame";
import {ALL_GAME_DATA} from "./util/GameData";
const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
      // Lazy load in development
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
        // For Embedded Mode
        // default: res.TanStackRouterDevtoolsPanel
      })),
    )

const rootRoute = createRootRoute({
  component: () => (
    <>
      <GameView/>
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </>
  ),
})

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  component: () => {
    return (
      <div className={"intro-page"}>
        <span>Welcome to Wordonyms!</span>
        <button className={"wj-button"} onClick={() => document.getElementById("game-view-kebab-menu")?.click()}>
          Pick a game
        </button>
      </div>
    )
  },
})

const WordJoinGameRoute = (): React.ReactElement => {
  const {gameId} = aboutRoute.useParams();
  const gameName = decodeURIComponent(gameId);
  const game = ALL_GAME_DATA.find((item) => item.name === gameName);
  if (!game) return <div>Hmm, couldn't find that puzzle. Try a <button>different one</button></div>
  return <WordJoinGame key={`${game.name}-key`} {...game}/>
}

const aboutRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/g/$gameId',
  component: WordJoinGameRoute,
})

const routeTree = rootRoute.addChildren([indexRoute, aboutRoute])

const router = createRouter({ routeTree })

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

export const Router = (): React.ReactElement => {
  return <RouterProvider router={router} />
}

