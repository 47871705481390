import React from 'react';
import './GameView.css';
import {GamePicker} from "./GamePicker";
import logo from "./icon/logo.svg";
import kebabicon from "./icon/kebab.svg";
import xicon from "./icon/x.svg";
import helpicon from "./icon/help.svg";
import themeicon from "./icon/theme-mode.svg";
import {UserStorage} from "./util/UserStorage";
import {Link, Outlet, useNavigate} from "@tanstack/react-router";
import {ALL_GAME_DATA} from "./util/GameData";
import {ThemeContext} from "./util/ThemeContext";
import {Icon} from "./icon/Icon";
import {HelpPopup} from "./popup/HelpPopup";


const calcHeaderHeight = (): number => {
  const headerElement = document.querySelector(".app-header")
  if (!headerElement) return 40;
  return headerElement.clientHeight;
}

export const GameView = (): React.ReactElement => {
  const [theme, setTheme] = React.useState(() => UserStorage.getTheme())
  const [showPopup, setShowPopup] = React.useState(false)
  const [headerSize, setHeaderSize] = React.useState(calcHeaderHeight);
  const [showHelp, setShowHelp] = React.useState(false);
  document.body.className = `${theme}-theme`;
  const navigate = useNavigate();

  const wrappedSetShowPopup = (v: boolean) => {
    setHeaderSize(calcHeaderHeight)
    setShowPopup(v)
  }

  return <ThemeContext.Provider value={theme}>
    <div className={"game-view"}>
      <div className={"game-view-top"}>
        <header className="app-header">
          <Link to={"/"} className="app-logo-link">
            <Icon alt={"logo"} src={logo} className={"app-logo"}/>
            <h1><span>W</span>ordonyms</h1>
          </Link>
          <div className={"app-header-right"}>
            <button className="icon-button"
                    onClick={() => {
                      const newTheme = theme === "dark" ? "light" : "dark"
                      setTheme(newTheme)
                      UserStorage.setTheme(newTheme)
                    }}>
              <Icon alt={"theme change icon"} src={themeicon} className={"theme-mode-icon"}/>
            </button>
            <button className="icon-button" onClick={() => {
              setShowHelp(!showHelp)
            }}>
              <Icon alt={"help icon"} src={helpicon} className={"help-icon"}/>
            </button>
            <button className="icon-button" id={"game-view-kebab-menu"} onClick={() => {
              wrappedSetShowPopup(!showPopup)
            }}>
              <Icon alt={"menu icon"} src={kebabicon} className={"kebab-icon"}/>
            </button>
          </div>
        </header>
        <div className="game-view-inner">
          <Outlet/>
        </div>
        {showPopup && <div className={"popup-overlay"}
                           onClick={(e) => e.target === e.currentTarget && wrappedSetShowPopup(false)}
                           style={{paddingTop: headerSize ?? 40}}>
            <div className={"game-picker-popup"}>
                <GamePicker onGameChange={(game) => {
                  setShowPopup(false);
                  navigate({
                    to: "/g/$gameId",
                    params: {
                      gameId: encodeURIComponent(game.name)
                    }
                  });
                }}
                            currentGame={undefined}
                            games={ALL_GAME_DATA}/>
            </div>
        </div>}
        {showHelp && <HelpPopup hidePopup={() => setShowHelp(false)}/>}
      </div>
      <div className={"game-view-bottom"}>Copyright © 2024 • <Link to={"https://sam.luna-nelson.com"} target={"_blank"}>Sam Luna-Nelson</Link></div>
    </div>
  </ThemeContext.Provider>
}
